<template>
    <v-card class="scroller">
      <v-row v-for="(item, index) in resources" :key="item.id">
      <!-- :loading="loading" -->
<!--       :options.sync="options" -->
      <!-- :server-items-length="totalCount" -->
        <!-- {{ index }} : {{ resource.id }} -->
        <slot name="item" :slot-scope="{ item, index }"></slot>
      </v-row>
      <v-btn @click="loadMore" v-if="!allResourcesLoaded">Load More</v-btn>
  </v-card>
</template>

<script>
import pagination from "@/mixins/pagination";

export default {
  name: "paginatedScroller",
  components: {},
  mixins: [ pagination ],
  data() {
    return {
    };
  },
  computed: {
    allResourcesLoaded() {
      return this.options.page * this.options.itemsPerPage >= this.fetchedData.total_count;
    }
  },
  methods: {
    loadMore() {
      if (this.loading || this.allResourcesLoaded) return;
      this.options.page++;
      this.getCurrentPage();
    }
  }
};
</script>
<style scoped>
.dscroller {
  overflow-y: auto
}
</style>
