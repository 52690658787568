<template>
  <v-row>
    <v-col>
      <v-card-text>
        <paginated-scroller
          :graphqlQuery="getGraphqlQuery()"
          resourceName="organisation_users"
          :filter="filter"
        >
          <template v-slot:item="{ slotScope: { item } }">
            <v-row>
              <v-col>
                <userItem :user="item.user">
                  <template v-slot:action>
                    <v-tooltip left color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          @click="addGroupMember(item.user.id)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="success" small
                            >mdi-account-plus</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ $t("groups.addUser") }}</span>
                    </v-tooltip>
                  </template>
                </userItem>
              </v-col>
            </v-row>
          </template>
        </paginated-scroller>
      </v-card-text>
    </v-col>
  </v-row>
</template>
<script>
import userItem from "@/components/users/userItem";
import paginatedScroller from "@/components/shared/paginatedScroller";
import PAGINATED_ORGANISATION_MEMBERS_INDEX from "@/graphql/organisations/paginated_members_index.gql";
import CREATE_GROUP_USER from "@/graphql/group_users/create.gql";
import { mapGetters } from "vuex";

export default {
  name: "addMembers",
  components: { paginatedScroller, userItem },
  props: {
    groupId: {
      type: String,
      required: true,
    },
    groupUsers: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["selectedOrganisationId"]),
    filter() {
      let str = `organisation_id == '${this.selectedOrganisationId}' `;
      for (var i = 0; i < this.groupUsers.length; i++) {
        str += `&& user_id != '${this.groupUsers[i].user.id}' `
      }
      return str;
    },
  },
  methods: {
    getGraphqlQuery() {
      return PAGINATED_ORGANISATION_MEMBERS_INDEX;
    },
    addGroupMember(user_id) {
      this.$apollo
        .mutate({
          mutation: CREATE_GROUP_USER,
          variables: {
            group_id: this.groupId,
            user_id: user_id,
          },
        })
        .then(({ data }) => {
          this.$emit('groupUserCreated');
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
  },
};
</script>
