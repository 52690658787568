<template>
  <v-container fluid class="pa-0" style="height: 100%">
    <headerToolbar color="white" divider>
      <template v-slot:toolbarTitle>
        <b class="ml-3">{{ $t("navigationMenu.groups") }}</b>
        <newGroup ref="newGroup" @groupCreated="onGroupCreated" />
      </template>
    </headerToolbar>

    <v-container
      fluid
      fill-height
      class="pa-0"
      v-if="groupsData.length == 0 && loading == false"
    >
      <v-row class="text-center"
        ><v-col cols="12">
          <v-img
            class="mx-auto mb-8"
            width="320"
            src="images/groups.png"
          ></v-img>
          <h2>{{ $t("groups.createGroup") }}</h2>
          <span class="body-2" v-html="$t('groups.groupDesc')"></span>
        </v-col>
        <v-col cols="12">
          <v-btn
            depressed
            color="button_blue"
            class="white--text"
            rounded
            @click="$refs.newGroup.dialog = true"
            ><v-icon left small>mdi-plus</v-icon
            >{{ $t("groups.createGroup") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else fluid style="height: calc(100vh - 72px)" class="pa-0">
      <v-row wrap no-gutters  style="height: calc(100vh - 72px)" >
        <v-col class="pa-8 light_grey_background" cols="6"  style="height: calc(100vh - 72px)" >
          <v-container
            fluid
            class="py-0"
            style="height: 100%; overflow-y: auto"
          >
            <template v-for="group in groupsData">
              <v-hover v-slot="{ hover }" :key="group.id">
                <v-card
                  class="mb-4"
                  :ripple="false"
                  flat
                  :class="{ 'elevation-4': group.id == selectedGroupId }"
                  :dark="hover || selectedGroupId == group.id"
                  :color="hover ? 'button_blue lighten-2' : 'white'"
                  @click="selectedGroupId = group.id"
                >
                  <v-list
                    :color="getListItemColor(hover, group.id)"
                    dense
                    class="py-0"
                    two-line
                  >
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon class="main_blue" dark>
                          mdi-account-group
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          v-text="group.name"
                        ></v-list-item-title>

                        <v-list-item-subtitle>
                          {{
                            group.group_users.length +
                            " " +
                            $t(
                              "groups." +
                                (group.group_users.length > 1
                                  ? "members"
                                  : "member")
                            )
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action style="display: contents">
                        <v-chip color="transparent" small label class="mr-2">
                          <v-icon small>{{
                            group.private ? "mdi-incognito" : "mdi-earth"
                          }}</v-icon
                          ><span class="ml-1 hidden-md-and-down">
                            {{
                              group.private
                                ? $t("groups.privateGroup")
                                : $t("groups.publicGroup")
                            }}</span
                          ></v-chip
                        >

                        <userAvatarGroup
                          :limit="3"
                          :users="group.group_users.map((e) => e.user)"
                          :size="24"
                          class="hidden-md-and-down"
                          :avatar_border="!(hover || selectedGroupId == group.id)"
                        />

                        <v-menu bottom left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="selectedGroupId = group.id"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-list dense>
                              <v-list-item
                                @click="showRenameGroupDialog(group.id)"
                              >
                                <v-list-item-icon class="mr-1">
                                  <v-icon small>mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    $t("groups.renameGroup")
                                  }}</v-list-item-title></v-list-item-content
                                >
                              </v-list-item>

                              <v-list-item
                                :disabled="loading"
                                @click.stop="
                                  togglePrivate(group.id, !group.private)
                                "
                              >
                                <v-list-item-icon class="mr-1">
                                  <v-icon
                                    :color="group.private ? 'button_blue' : ''"
                                    size="18"
                                    >{{
                                      group.private
                                        ? "mdi-checkbox-marked"
                                        : "mdi-checkbox-blank-outline"
                                    }}</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    $t("groups.privateGroup")
                                  }}</v-list-item-title></v-list-item-content
                                >
                              </v-list-item>

                              <v-list-item @click="deleteGroup(group.id)">
                                <v-list-item-icon class="mr-1">
                                  <v-icon color="error" small
                                    >mdi-delete</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title class="error--text">{{
                                    $t("groups.deleteGroup")
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-hover>
            </template></v-container
          >
        </v-col>
        <v-col class="pa-8" cols="6" style="height: 100%">
          <v-container fluid class="py-0" style="height: 100%">
            <v-card flat class="px-4 py-0" color="white" height="100%">
              <v-card-title class="pa-0">
                <v-row wrap no-gutters>
                  <v-col cols="12" class="pa-0">
                    <v-subheader class="font-weight-bold">
                      {{ $t("groups.groupMembers") }}:
                    </v-subheader></v-col
                  >
                  <v-col cols="12" class="pa-0">
                    <searchAndSelectUsers
                      :selectedUsersIds="
                        selectedGroup.group_users.map((u) => u.user.id)
                      "
                      @onUserSelected="addGroupMember"
                      @onUserUnselected="eventDeleteGroupMember"
                      :selectedMessage="$t('groups.memberOfTheGroup')"
                      v-if="selectedGroup"
                    />
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text
                class="pa-0 pt-0"
                style="height: calc(100% - 112px); overflow-y: auto"
              >
                <v-list dense two-lines class="py-0" v-if="selectedGroup">
                  <userItem
                    v-for="group_user in selectedGroup.group_users"
                    :key="group_user.id"
                    :user="group_user.user"
                  >
                    <template v-slot:action>
                      <v-tooltip left color="error">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            @click="deleteGroupMember(group_user)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="error" small
                              >mdi-account-minus</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>{{ $t("groups.removeUser") }}</span>
                      </v-tooltip>
                    </template>
                  </userItem>
                  <v-row
                    no-gutters
                    v-if="selectedGroup.group_users.length === 0"
                  >
                    <v-col>
                      <span class="caption mx-4">
                        {{ $t("groups.empty") }}
                      </span>
                    </v-col>
                  </v-row>
                </v-list>
              </v-card-text>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="RenameGroupDialog"
      max-width="480"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ $t("groups.renameGroup") }}</span>
          <v-btn icon @click="closeRenameGroupDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-5">
          <v-text-field
            v-model="renameGroupString"
            :label="$t('groups.groupName')"
            filled
            dense
            autocomplete="off"
            @keyup.enter="loading ? null : renameGroup()"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="closeRenameGroupDialog()" color="almost_black">
            {{ $t("misc.cancel") }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="button_blue"
            class="white--text"
            depressed
            @click="renameGroup()"
            :disabled="loading"
          >
            {{ $t("misc.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import headerToolbar from "../shared/headerToolbar";
import GROUPS_INDEX from "@/graphql/groups/index.gql";
import UPDATE_GROUP from "@/graphql/groups/update.gql";
import userAvatarGroup from "../shared/userAvatarGroup";
import userItem from "@/components/users/userItem";
import newGroup from "./newGroup";
import addMembers from "./addMembers";
import searchAndSelectUsers from "./../users/searchAndSelectUsers";
import CREATE_GROUP_USER from "@/graphql/group_users/create.gql";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "groups_index",
  components: {
    addMembers,
    headerToolbar,
    newGroup,
    userAvatarGroup,
    userItem,
    searchAndSelectUsers,
  },
  data() {
    return {
      groupsData: [],
      selectedGroupId: "",
      RenameGroupDialog: false,
      renameGroupString: "",
      loading: false,
      displayAddMembers: false,
    };
  },
  computed: {
    ...mapGetters(["selectedOrganisationId"]),
    selectedGroup() {
      if (this.groupsData.length && this.selectedGroupId)
        return this.groupsData.find((g) => g.id == this.selectedGroupId);
      return null;
    },
  },
  methods: {
    ...mapActions(["openDestroyDialog"]),
    createGroup() {},
    onGroupCreated(groupId) {
      this.selectedGroupId = groupId;
      this.getGroups();
    },
    getGroups() {
      if (!this.selectedOrganisationId) return;
      this.loading = true;
      this.$apollo
        .query({
          query: GROUPS_INDEX,
          variables: {
            filter: `organisation_id == '${this.selectedOrganisationId}'`,
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.groupsData = data.groups.sort(this.sortByName);
          this.selectDefaultGroup();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.sendError(e);
        });
    },
    selectDefaultGroup() {
      if (this.groupsData.length === 0) {
        this.selectedGroupId = null;
        return;
      } else if (
        !this.selectedGroupId ||
        !this.isGroupPresent(this.selectedGroupId)
      ) {
        this.selectedGroupId = this.groupsData[0].id;
        return;
      }
    },
    isGroupPresent(groupId) {
      if (!groupId) return false;
      return this.groupsData.map((e) => e.id).includes(groupId);
    },
    sortByName(a, b) {
      return 2 * (a.name > b.name) - 1;
    },
    renameGroup() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_GROUP,
          variables: {
            id: this.selectedGroupId,
            name: this.renameGroupString,
          },
        })
        .then(({ data }) => {
          this.closeRenameGroupDialog();
          this.loading = false;
          this.getGroups();
        })
        .catch((error) => {
          this.sendError(error);
          this.loading = false;
        });
    },
    closeRenameGroupDialog() {
      this.renameGroupString = "";
      this.RenameGroupDialog = false;
    },
    deleteGroup(id) {
      this.selectedGroupId = id;
      this.openDestroyDialog({
        id: id,
        model: "group",
        onDestroy: this.onGroupDestroy,
        title: this.$t("groups.deleteGroupAlertTitle"),
        message: this.$t("groups.deleteGroupAlertMessage").replace(
          "<groupname>",
          this.selectedGroup.name
        ),
      });
    },
    deleteGroupMember(group_user) {
      let _user = this.group;
      this.openDestroyDialog({
        id: group_user.id,
        model: "group_user",
        onDestroy: this.onGroupDestroy,
        title: this.$t("groups.deleteGroupUserAlertTitle"),
        message: this.$t("groups.deleteGroupUserAlertMessage")
          .replace("<groupname>", this.selectedGroup.name)
          .replace(
            "<username>",
            group_user.user.first_name + " " + group_user.user.last_name
          ),
      });
    },
    onGroupDestroy() {
      this.getGroups();
    },
    togglePrivate(id, isPrivate) {
      this.selectedGroupId = id;
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_GROUP,
          variables: {
            id: id,
            private: isPrivate,
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.getGroups();
        })
        .catch((error) => {
          this.sendError(error);
          this.loading = false;
        });
    },
    showRenameGroupDialog(id) {
      this.selectedGroupId = id;
      this.RenameGroupDialog = true;
      this.renameGroupString = this.selectedGroup.name;
    },
    addGroupMember(user) {
      console.log("Event!!!!");
      this.$apollo
        .mutate({
          mutation: CREATE_GROUP_USER,
          variables: {
            group_id: this.selectedGroupId,
            user_id: user.id,
          },
        })
        .then(({ data }) => {
          this.getGroups();
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    eventDeleteGroupMember(event_user) {
      let _group_user_id = this.selectedGroup.group_users.find(
        (gu) => gu.user.id == event_user.id
      );
      this.deleteGroupMember(_group_user_id);
    },
    getListItemColor(hover, group_id) {
      if (this.selectedGroupId == group_id) return "button_blue";
      if (hover) return "button_blue lighten-2";
      return "white";
    },
  },
  mounted() {
    this.getGroups();
  },
  watch: {
    selectedOrganisationId() {
      this.getGroups();
    },
  },
   created() {
    document.title = this.$t("navigationMenu.groups");
    this.$amplitude.getInstance().logEvent("Page: Teams");
  },
};
</script>

<style>
.theme--light.v-application {
  background-color: white
}


</style>
