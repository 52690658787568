<template>
  <v-dialog max-width="480" transition="dialog-top-transition" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-6"
        small
        color="button_blue"
        dark
        depressed

      >
        <v-icon small class="pr-2">mdi-plus</v-icon>
        {{ $t("headerActions.addNewGroup") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title font-weight-regular justify-space-between">
        <span>{{ formTitle }}</span>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!--  <v-row no-gutters class="pb-4">
          <v-col cols="12" align="center">
            <v-icon
              class="px-1"
              small
              :color="formStep ? 'active_purple' : 'button_blue'"
              >mdi-checkbox-blank-circle</v-icon
            >
            <v-icon
              class="px-1"
              small
              :color="formStep ? 'button_blue' : 'active_purple'"
              >mdi-checkbox-blank-circle</v-icon
            >
          </v-col>
        </v-row> -->

      <v-window v-model="formStep">
        <v-window-item :value="0">
          <v-card-text class="px-5 pb-2">
            <v-text-field
              v-model="name"
              :label="$t('groups.name')"
              placeholder=""
              filled
              dense
              autocomplete="off"
              @keyup.enter="loading ? null : createGroup()"
              hide-details
            />

            <v-subheader class="pl-2 pt-4">{{$t("groups.visibility")}}</v-subheader>
            <v-checkbox
            class="mt-0 ml-1"
              v-model="isPrivate"
              :label="$t('groups.privateGroup')"
              color="button_blue"
              :value="isPrivate"
              hide-details
              dense
            >
            </v-checkbox>

            <v-row wrap no-gutters class="pt-4 pb-0">
              <v-col cols="12" align="center">
                <span class="caption">
                  <i>{{ $t(isPrivate ? "groups.privateDescription" : "groups.publicDescription") }}</i>
                </span>

              </v-col>
            </v-row>

            <!-- <template v-slot:selection="data">
                {{ data.item.first_name + " " + data.item.last_name}}
              </template> -->
          </v-card-text>
        </v-window-item>

        <!--  <v-window-item :value="1">
            <add-members :groupId="createdGroupId" v-if="createdGroupId" />
          </v-window-item> -->
      </v-window>

      <v-divider></v-divider>

      <v-card-actions>
        <!--   <v-btn
            :disabled="formStep === 0"
            text
            @click="formStep = 0"
            color="almost_black"
          >
            <v-icon class="pr-1" small>mdi-arrow-left-thick</v-icon>
            {{ $t("misc.back") }}
          </v-btn>
 -->
        <v-spacer></v-spacer>

        <v-btn
          v-if="formStep === 0"
          color="button_blue"
          class="white--text"
          depressed
          @click="createGroup()"
          :disabled="loading || !name"
        >
          {{ $t("misc.create") }}
          <v-icon class="pl-1" small>mdi-arrow-right-thick</v-icon>
        </v-btn>

        <!-- <v-btn
            v-if="formStep === 1"
            color="success"
            class="white--text"
            depressed
            @click="close"
          >
            {{ $t("misc.finish") }}
          </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import userAvatar from "../shared/userAvatar";
import CREATE_GROUP from "@/graphql/groups/create.gql";
import addMembers from "./addMembers";

export default {
  name: "addNewGroup",
  mixins: [],
  components: { userAvatar, addMembers },
  data() {
    return {
      dialog: false,
      name: "",
      isPrivate: false,
      formStep: 0,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["selectedOrganisationId"]),
    formTitle() {
      switch (this.formStep) {
        case 0:
          return this.$t("groups.createGroup");
        case 1:
          return this.$t("groups.addMembers");
      }
    },
  },
  methods: {
    close() {
      this.formStep = 0;
      this.dialog = false;
    },
    createGroup() {
      this.loading = true;
      console.log(this.isPrivate);
      this.$apollo
        .mutate({
          mutation: CREATE_GROUP,
          variables: {
            organisation_id: this.selectedOrganisationId,
            name: this.name,
            private: this.isPrivate,
          },
        })
        .then(({ data }) => {
          this.loading = false;
          //this.formStep += 2;
          this.$emit("groupCreated", data.create_group.id);
          this.name = "";
          this.isPrivate = false;
          this.close();
          //this.createdGroupId = data.create_group.id;
        })
        .catch((error) => {
          this.sendError(error);
          this.loading = false;
        });
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

